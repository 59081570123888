/**
* Generated automatically at built-time (2025-04-01T08:54:49.315Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { getComponentClassName } from "@utils/strings";
import { getScreenTemplates, mergeHelmet } from "../utils";
export default ({screen, siteContext})=>{
const helmet = mergeHelmet(siteContext.helmet, screen.helmet);
const pageId = "37fac89f-fca6-43d7-b3ca-548d53f85b90";
return {[pageId]: {id: pageId,helmet,className: getComponentClassName(pageId),items: getScreenTemplates(screen)}};
}