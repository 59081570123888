/**
* Generated automatically at built-time (2025-04-01T08:54:49.210Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "impressum",templateKey: "sites/105-150b1a59-8d2a-4616-bb13-27ea4847861f"};